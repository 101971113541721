import { extractNumber } from "./extractNumber";
import languageCurrencySwitch from "./languageCurrencySwitch";
import productSort from "./productsSort";
import { ProductListingProps } from "./types";

const filterPrice = (feed: any[] | null, id: string | null, locale: string | null) => {
  let priceInfo = { salePrice: "", price: "", savings: "", stock: false, discount: 0 };
  if (feed && id) {
    let product = feed.find((item: any) => item["g:id"].toString() === id);
    if (product && product["g:sale_price"]) {
      priceInfo.salePrice = languageCurrencySwitch(product["g:sale_price"], locale);
    }
    if (product && product["g:price"]) {
      priceInfo.price = languageCurrencySwitch(product["g:price"], locale);
    }
    if (product && product["g:availability"] && product["g:availability"] === "in_stock") {
      priceInfo.stock = true;
    }
    if (product && product["g:sale_price"] && product["g:price"]) {
      const num1 = extractNumber(product["g:price"]);
      const num2 = extractNumber(product["g:sale_price"]);
      const difference = num1 - num2;
      priceInfo.savings = `${difference.toFixed(2)}`;
      priceInfo.discount = (num2 / num1) * 100;
    }
  }
  return priceInfo;
};

const getPriceInfo = ({ feed, productListing, locale }: { feed: any[] | null; productListing: ProductListingProps[]; locale: string | null }) => {
  if (productListing) {
    let list = productListing.map((item: ProductListingProps) => {
      let priceInfo = filterPrice(feed, item.apiFeedId, locale);
      item.price = priceInfo?.price;
      item.salePrice = priceInfo?.salePrice;
      item.savings = priceInfo?.savings;
      item.stock = priceInfo?.stock;
      item.discount = priceInfo?.discount;
      return item;
    });
    if (feed) {
      return productSort(list.filter((itm) => itm.stock));
    }
    return productSort(list);
  }
  return [];
};

export default getPriceInfo;

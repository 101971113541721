import axios from "axios";
import { XMLParser } from "fast-xml-parser";

const parse_xml = async (url: string | undefined) => {
  try {
    if (url) {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/xml",
        },
      });
      const data = response.data;
      return convert_xml(data);
    }
  } catch (err) {
    console.log(err);
  }
};

const convert_xml = (xml: string | Buffer) => {
  const parser = new XMLParser();
  let result = parser.parse(xml);
  if (result && result["rss"] && result["rss"]["channel"] && result["rss"]["channel"]["item"]) {
    return result["rss"]["channel"]["item"];
  } else {
    throw new Error("XML Fetch Failed");
  }
};

export default parse_xml;

import { FC, useMemo } from "react";
import { CtaTextColor, CtaVariant } from "../../util/types";

interface ButtonProps extends React.HTMLAttributes<HTMLAnchorElement> {
  variant: CtaVariant;
  color: CtaTextColor;
  children: string;
  to: string;
  underlined?: boolean;
  icons?: boolean;
  className?: string;
  title?: string;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
  variant = "contained",
  color = "black",
  to = "#",
  className = "",
  children,
  disabled = false,
  title = children,
  ...rest
}) => {
  const location = window.location;
  const url: string = useMemo(() => {
    if (to.includes("https")) {
      return to;
    }
    return location.origin + location.pathname + "#" + to;
  }, [location, to]);
  const ctaClasses = useMemo(() => {
    return `cta cta--${variant} cta--${color} ${variant === "underlined" ? `cta--arrow` : ""} ${disabled ? `cta--disabled` : ""} ${className}`;
  }, [variant, color, className, disabled]);

  return (
    <a title={title} className={ctaClasses} href={url} {...rest}>
      <span>{children}</span>
    </a>
  );
};

export default Button;

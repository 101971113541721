/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from "react";
import { MenuItem } from "../../../util/types";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/navbar.css";
import NavItem from "./NavItem";
import { DropDownItem, NavDropDown } from "./NavDropdown";
import Close from "../Icons/Close";
import Menu from "../Icons/Menu";

interface NavigationBarProps {
  headerData: MenuItem[];
}

type dropDownProps = {
  label: string;
  open: boolean;
};

interface NavMenusProps {
  menus: MenuItem[];
  toggleDropdown: () => void;
}
const NavMenus: FC<NavMenusProps> = ({ menus, toggleDropdown }) => {
  const [dropdown, setDropDown] = useState<dropDownProps>({
    label: "",
    open: false,
  });

  const handleDropdown = (label: string) => {
    if (label.length === 0) {
      toggleDropdown();
      return setDropDown({ label: "", open: false });
    }
    if (label === dropdown.label) {
      return setDropDown({ ...dropdown, open: !dropdown.open });
    }
    return setDropDown({ label: label, open: true });
  };

  return (
    <ul className="cpcs-esis-navbar-nav ml-auto">
      {menus.map((item, index) => {
        return item.menus && item.menus.length > 0 ? (
          <NavDropDown label={item.menuLabel} onClick={handleDropdown} dropdown={dropdown} key={index}>
            {item.menus &&
              item.menus.length > 0 &&
              item.menus.map((submenu, i) => <DropDownItem label={submenu.menuLabel} link={submenu.menuLink} key={i} onClick={handleDropdown} />)}
          </NavDropDown>
        ) : (
          <NavItem {...item} key={index} toggleDropdown={toggleDropdown} />
        );
      })}
    </ul>
  );
};

interface ToggleProps {
  open: boolean;
  classes: string;
}

const NavigationBar: FC<NavigationBarProps> = ({ headerData }) => {
  const navigate = useNavigate();
  const toggleRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<ToggleProps>({
    open: false,
    classes: "",
  });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)");

    const handleMobileChange = (event: MediaQueryListEvent) => {
      setIsMobile(event.matches);
    };

    mobileMediaQuery.addEventListener("change", handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleMobileChange);
    };
  }, []);

  const toggleDropdown = () => {
    if (isMobile) {
      if (isOpen.open) {
        document.body.classList.remove("nav-open");
        return setIsOpen({ open: false, classes: "hide" });
      }
      document.body.classList.add("nav-open");
      return setIsOpen({ open: true, classes: "show" });
    }
  };

  return (
    <nav className="cpcs-esis-navbar cpcs-esis-navbar-expand-lg">
      <a className="cpcs-esis-navbar-brand " onClick={() => navigate("/")}>
        <div className="img-wrapper">
          <img src="https://media.samsungepartners.com/common/images/samsung-logo.svg" alt="SAMSUNG" />
        </div>
      </a>
      <button className="cpcs-esis-navbar-toggler" onClick={toggleDropdown}>
        {<Menu />}
      </button>
      <div className={`cpcs-esis-navbar-collapse collapse ${isOpen.classes}`} ref={toggleRef}>
        <div className="cpcs-esis-navbar-btn-container">
          <button className="cpcs-esis-navbar-close-btn" onClick={toggleDropdown}>
            <Close />
          </button>
        </div>
        <NavMenus menus={headerData} toggleDropdown={toggleDropdown} />
      </div>
    </nav>
  );
};

export default NavigationBar;

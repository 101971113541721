import { ShopBySelector } from "../../util/types";
import Section from "../Elements/Section";
import Image from "../Elements/Image";
import ProductTitle from "../Elements/Typography/ProductTitle";
import Title from "../Elements/Typography/Title";

interface SelectorsMasterItemProps {
  selectorImgUrl: string;
  selectorTitle: string;
  selectorURL: string;
}
const SelectorsMasterItem: React.FC<SelectorsMasterItemProps> = ({ selectorImgUrl, selectorTitle, selectorURL }) => {
  return (
    <div className="cpcs-esis_shopby-item">
      <div className="cpcs-esis_shopby-item-inner">
        <a href={selectorURL} className="cta-selector" title={selectorTitle}>
          <div className="cpcs-esis_shopby-item-img">
            <Image src={selectorImgUrl} alt={selectorTitle} />
          </div>
          <div className="cpcs-esis_shopby-item-title">
            <ProductTitle className="cpcs-esis_shopby-item-title-inner" size="medium" title={selectorTitle} />
          </div>
        </a>
      </div>
    </div>
  );
};

const Cc04ShopBySelector: React.FC<ShopBySelector> = ({ headline, selectorListing }) => {
  return (
    <Section>
      <div className="cpcs-esis_shopby-container">
        <Title className="cpcs-esis_shopby-title" heading={headline} />
        <div className="cpcs-esis_shopby-listing">
          <div className="cpcs-esis_shopby-listing-inner">
            {selectorListing &&
              selectorListing.map((selector) => (
                <SelectorsMasterItem
                  key={selector.id}
                  selectorImgUrl={selector.selectorImgUrl}
                  selectorTitle={selector.selectorTitle}
                  selectorURL={selector.selectorURL}
                />
              ))}
          </div>
        </div>
      </div>
    </Section>
  );
};
export default Cc04ShopBySelector;
